import "./sectionFive.scss";
import { Col, Container, Row } from "rsuite";
import greenLogo from "../../assets/imgs/greenLogo.png";
import roseLogo from "../../assets/imgs/roseLogo.png";
import blueLogo from "../../assets/imgs/blueLogo.png";

const SectionFive = () => {
  return (
    <Container className="container">
      <Row>
        {/* <div className="logos"> */}
        <Col sm={8} md={8} className="logos">
          <img src={greenLogo} className="Logo" alt="Logo" />
          <div className="textos">
            Contamos con un{" "}
            <span className="boldText">
              catálogo de cientos de opciones disponibles
            </span>{" "}
            para poner tu logo en boca de todos, pero además, llevamos tus ideas
            a otro nivel con diseños que harán destacar a tu marca.
          </div>
        </Col>
        <Col sm={8} md={8} className="logos">
          <img src={roseLogo} className="Logo" alt="Logo" />
          <div className="textos">
            Creamos y personalizamos <span className="boldText">los uniformes corporativos o industriales </span>
            que requieras para tu empresa. ¡Las posibilidades son infinitas!
          </div>
        </Col>
        <Col sm={8} md={8} className="logos">
          <img src={blueLogo} className="Logo" alt="Logo" />
          <div className="textos">
            Te acompañamos <span className="boldText">en la estrategia, logística y ejecución de
            experiencias únicas y memorables</span> acordes a la medida de tus
            necesidades.
          </div>
        </Col>
        {/* </div> */}
      </Row>
    </Container>
  );
};

export default SectionFive;
