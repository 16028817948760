import { Row, Container } from "rsuite";
import Instagram from "../../assets/imgs/instagram.png";
import Phone from "../../assets/imgs/phone.png";
import Dots from "../../assets/imgs/dots.png";
import "./footer.scss";

const Footer = () => {
  return (
    <Container>
      <div className="box-f">
        <div className="box-footer" id="contacto">
          <Row className="links">
            <a href="https://www.instagram.com/Bolluk.MX" target="_blank" rel="noreferrer">
              <img src={Instagram} className="icon" alt="InstagramLogo" />
              <span>@Bolluk.MX</span>
            </a>
            <a href="tel:5522902412">
              <img src={Phone} className="icon" alt="Phone" />
              <span>5522902412</span>
            </a>
            <a href="mailto:contacto@bolluk.mx">contacto@bolluk.mx</a>
          </Row>
        </div>
        <img src={Dots} alt="dots" className="dots" />
        <div className="text">
          Todos los derechos reservados por Bolluk. {new Date().getFullYear()}.
        </div>
      </div>
    </Container>
  );
};

export default Footer;
