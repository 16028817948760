import 'rsuite/dist/rsuite.min.css'
import './App.scss';
import NavBar from "./components/navBar/navBar";
import SectionFour from './components/sectionFour/sectionFour';
import SectionOne from "./components/sectionOne/sectionOne";
import SectionThree from './components/sectionThree/sectionThree';
import SectionTwo from './components/sectionTwo/sectionTwo';
import SectionFive from './components/sectionFive/sectionFive';
import SectionSix from './components/sectionSix/sectionSix';
import Footer from './components/footer/footer';

function App() {
  return (
    <section>
      <NavBar/>
      <SectionOne/>
      <SectionTwo/>
      <SectionThree/>
      <SectionFour/>
      <SectionFive/>
      <SectionSix/>
      <Footer/>
    </section>
  );
}

export default App;
