import { Col, Container } from "rsuite";
import Logo from "../../assets/imgs/Logo.png";
import "./sectionSix.scss";

const SectionSix = () => {
  return (
    <Container className="container">
      <div className="box">
        <img src={Logo} alt="Bolluk Logo" />
        <Col smHidden xsHidden>
          <div className="texto">
            Sabemos lo importante que es la imagen de tu empresa. <br />
            Nuestra misión es convertirnos en tus aliados para <br />
            <span className="boldText">IMPULSAR TU MARCA</span> y dejar huella
            juntos.
          </div>
        </Col>
        <Col xxlHidden xlHidden lgHidden mdHidden>
          <div className="texto">
            Sabemos lo importante que es<br />la imagen de tu empresa.<br /> 
            Nuestra misión es convertirnos<br />en tus aliados para<br />
            <span className="boldText">IMPULSAR TU MARCA</span><br /> y dejar huella
            juntos.
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default SectionSix;
