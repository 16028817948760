import { Col, Container } from "rsuite";
import "./sectionTwo.scss";

const SectionTwo = () => {
  return (
    <Container className="weare" id="nosotros">
      <Col smHidden xsHidden>
      <p className="textWeAre">
        Sin importar lo grande que sea tu idea o lo desafiante <br />
        que resulte un proyecto, en <span className="boldText">
          Bolluk
        </span>{" "}
        encuentras más que <br />
        un proveedor: encuentras un aliado que
        <br />
        <span className="boldText"> produce soluciones.</span>
      </p>
      </Col>
     <Col xxlHidden xlHidden lgHidden mdHidden>
     <p className="textWeAreMobile">
        Sin importar lo grande que sea tu idea<br /> o lo desafiante 
        que resulte un proyecto,<br /> en <span className="boldText">
          Bolluk
        </span>{" "}
        encuentras más que <br />
        un proveedor: encuentras un aliado que
        <br />
        <span className="boldText"> produce soluciones.</span>
      </p></Col>
      <p className="numbersDontLie">LOS NÚMEROS <span className="boldText">NO MIENTEN</span></p>
    </Container>
  );
};

export default SectionTwo;
