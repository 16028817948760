import "./sectionFour.scss";
import Map from "../../assets/imgs/mapa.svg";
import MapMobile from "../../assets/imgs/LogoMobile.png"
import { Container, Col } from "rsuite";

const SectionFour = () => {
  return (
    <Container className="container" id="servicios">
      <Col smHidden xsHidden>
      <div className="textWeDo">
        Nuestra cadena de producción está en un mismo espacio,<br/>
        lo que nos permite controlar el proceso completo,<br/>
        para así <span className="boldText"> garantizar calidad y puntualidad.</span>
      </div>
        <div className="container-mapa">
        <img src={Map} className="mapa" alt="trabajos" />
      </div>
      </Col>
      <Col xxlHidden xlHidden lgHidden mdHidden>
      <div className="textWeDo">
        Nuestra cadena de producción<br/> está en un mismo espacio,<br/>
        lo que nos permite controlar<br/> el proceso completo,<br/>
        para así <span className="boldText"> garantizar calidad<br/> y puntualidad.</span>
      </div>
        <div className="container-mapa">
        <img src={MapMobile} className="mapa-mobile" alt="trabajos" />
      </div>
      </Col>
    </Container>
  );
};

export default SectionFour;
