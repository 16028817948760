import { Col, Container, Row } from "rsuite";
import "./sectionThree.scss";

const SectionThree = () => {
  return (
    <Container className="container">
      <Row>
        <Col xs={24} className="green">
          <div className="boldText">46,680 h </div>
          <div className="textos">
            <div className="lightText">
              <Col smHidden xsHidden>
                <span>Trabajando para ti</span>
              </Col>
              <Col xxlHidden xlHidden lgHidden mdHidden>
                <span>Trabajando</span> <br />
                <span>para ti</span>
              </Col>
            </div>
          </div>
        </Col>
        <Col md={6} xs={24} sm={24}>
          <div className="group">
            <Row>
              {/* <div className="rose1"> */}
              <Col smHidden xsHidden className="rose1">
                <div className="text">
                  <div className="boldText">Más de</div>
                  <div className="bolderText">570</div>
                  <div className="lightText">
                    Clientes atendidos
                    <br className="spanjr"/> desde 2019
                  </div>
                </div>
              </Col>
              {/* </div> */}
            </Row>
          </div>
        </Col>
        <Col md={18} xs={24} sm={24}>
          <div className="group2">
            <div className="lightBlue">
              <div className="boldText">MÁS DE 5,000 </div>
              <div className="textos">
                <div className="lightText">Productos en</div>
                <div className="lightText">nuestro catálogo</div>
              </div>
            </div>
            <div className="group3">
              <div className="Blue">
                <div className="boldText">100% </div>
                <div className="lightText">Entregas puntuales</div>
              </div>
              <div className="rose2">
                <div className="boldText">93,883,935 </div>
                <div className="lightText">Productos entregados</div>
              </div>
            </div>
            <Col sm={24} xxlHidden xlHidden lgHidden mdHidden>
              <div className="roseContainer">
                <div className="rose3">
                  <div className="text">
                    <div className="boldText">Más de</div>
                    <div className="bolderText">570</div>
                    <div className="lightText">
                      Clientes atendidos <br />
                      desde 2019
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionThree;
