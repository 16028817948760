import ColorLogo from "../../assets/imgs/colorsLogo.png";
import "./sectionOne.scss";

const SectionOne = () => {
  return (
      <div className="brand">
        <img src={ColorLogo} alt="Bolluk Color Logo" className="colorLogoImg"/>      
      </div>
  );
};

export default SectionOne;
